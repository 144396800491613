/* eslint-disable import/no-anonymous-default-export */
export default [
  [
    {
      heading: "Customer Service",
      details:
        "Our team of customer service representatives undergoes comprehensive training to deliver outstanding assistance across a range of communication channels, including phone, email, chat, and social media. They adeptly manage customer inquiries and concerns, furnish detailed product information, and guide customers in making informed purchase decisions. Additionally, our agents are well-prepared to support customers throughout their journey – from initial onboarding to post-purchase follow-ups. They excel at tailoring solutions to address the distinctive needs of each customer.",
    },
  ],
  [
    {
      heading: "Order taking and Processing",
      details:
        "Efficiency and precision define our Order Taking and Processing service, crafted to enhance the customer ordering journey. Our adept agents undergo specialized training to seamlessly and accurately handle orders, maintaining optimal inventory levels and collaborating with vendors to guarantee product availability. Proficient in tracking and updating order statuses, our team excels in managing returns and exchanges, swiftly addressing any issues that may arise during the order fulfillment process. Trust us to streamline your ordering experience, ensuring accuracy and timely delivery.",
    },
  ],
  [
    {
      heading: "TECHNICAL SUPPORT",
      details:
        "Effective solutions, our team guides customers through troubleshooting steps, promptly escalates issues when necessary, and strives to guarantee satisfaction in every interaction. Count on us to deliver comprehensive technical support, ensuring a seamless resolution to any product or service-related concerns.",
    },
  ],
  [
    {
      heading: "Sales Support",
      details:
        "Elevating the customer's purchasing journey and boosting sales revenue is the core of our Sales Support service. Our proficient agents specialize in furnishing comprehensive product information, addressing inquiries, and overcoming customer concerns or objections. Leveraging upselling and cross-selling techniques, our team aims to not only increase sales revenue but also enhance overall customer satisfaction. Trust us to guide your customers towards informed purchase decisions, optimizing their experience and contributing to the growth of your sales.",
    },
  ],
  [
    {
      heading: "PAYMENT PROCESSING",
      details:
        "Facilitating a secure and efficient payment experience is the focal point of our Payment Processing service. Our skilled agents, trained in payment processing, utilize cutting-edge tools and technologies to guarantee accurate and timely payment transactions. In addition to their proficiency in processing payments, our team offers proactive payment reminders, conducts follow-ups on payment matters, and addresses any concerns or queries customers may have, ensuring a smooth and reliable payment process.",
    },
  ],
  [
    {
      heading: "UPSELLING AND CROSS-SELLING",
      details:
        "Elevating sales revenue and enhancing customer satisfaction drive our Upselling and Cross-selling service. Our agents, well-versed in the art of selling, adopt a consultative approach to propose additional products or services tailored to meet customer needs. Proficient in identifying opportunities for upselling and cross-selling, our team excels in building rapport with customers. By providing comprehensive product information, we empower customers to make informed purchase decisions, ensuring a mutually beneficial and satisfying experience.",
    },
  ],
  [
    {
      heading: "COMPLAINT HANDLING",
      details:
        "Focusing on deescalating customer complaints and delivering personalized resolutions is at the core of our Complaint Handling service. Our agents, trained in empathy and active listening, excel in deescalating tense situations and understanding the root cause of each complaint. Equipped with the skills to provide tailored solutions that address the customer's needs, our team ensures that resolutions are both effective and customer-centric. Additionally, we prioritize customer satisfaction by conducting follow-ups to confirm the success of the resolution.",
    },
  ],
  [
    {
      heading: "CUSTOMER RETENTION",
      details:
        "Enhancing customer retention through exceptional service and support is the primary focus of our Customer Retention service. Our agents collaborate with businesses to comprehend their specific customer retention challenges and develop tailored solutions. Engaging in proactive outreach, our team ensures customer satisfaction by addressing concerns or issues, fostering strong relationships, and confirming the ongoing success of products or services. Trust us to not only retain your valuable customers but also to contribute to the sustained growth and loyalty of your business.",
    },
  ],
  [
    {
      heading: "CONTACT CENTER",
      details:
        "Introducing our Contact Center service – a comprehensive solution to assist businesses in effectively managing customer interactions across diverse channels such as phone, email, chat, and social media. Our seasoned team of experts is dedicated to ensuring uniform communication and robust support, aiming to elevate the overall customer experience and cultivate lasting customer loyalty. Trust us to optimize your customer interactions and drive sustained customer loyalty with our Contact Center service.",
    },
  ],
  [
    {
      heading: "TECHNOLOGY SERVICES",
      details:
        "Explore our Technology Services, designed to empower businesses with valuable insights derived from customer conversations. Leveraging advanced technology, we analyze speech patterns, identify customer sentiment, and extract crucial data to optimize your business strategies. With our technology-driven approach, we aim to provide businesses with a competitive edge by unlocking actionable intelligence from customer interactions.",
    },
  ],
];
