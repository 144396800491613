/* eslint-disable import/no-anonymous-default-export */
export default [
  [
    {
      heading: "TECHNICAL CHAT SUPPORT",
      details:
        "Navigate technical challenges effortlessly with our Technical Chat Support service! Our highly skilled chat support agents stand ready 24/7 to provide expert assistance, guiding customers through troubleshooting processes with clear and concise instructions. Whether it's a product or service concern, we ensure swift and effective resolution, ensuring customers get the help they need precisely when they need it.",
    },
  ],
  [
    {
      heading: "SALES CHAT SUPPORT",
      details:
        "Elevate your sales game with our Sales Chat Support service! Our skilled chat support agents excel in providing expert assistance, from product recommendations to pricing inquiries and seamless order processing. Armed with up-to-date information about our products and services, our team ensures customers make informed decisions swiftly. Our goal is not just to assist but to contribute to your success by enhancing sales and revenue through our dedicated sales chat support service.",
    },
  ],
  [
    {
      heading: "CUSTOMER CHAT SUPPORT",
      details:
        "Experience top-tier customer support with our Customer Chat Support service! Available 24/7, our highly trained chat support agents deliver prompt and effective assistance, handling inquiries, complaints, and feedback with a friendly and professional touch. Reflecting positively on your business, our team assists customers in navigating your platforms, answers queries about products or services, and provides swift solutions to ensure unparalleled customer satisfaction whenever they reach out.",
    },
  ],
  [
    {
      heading: "PRODUCT CHAT INFORMATION",
      details:
        "Elevate your customers' shopping experience with our Product Chat Information service! Our skilled chat support agents are adept at providing detailed and up-to-date information about your products or services, ensuring customers make informed decisions. Beyond that, we assist in navigating your website or mobile app, offering personalized product recommendations, and swiftly addressing queries. With a focus on enhancing the overall shopping journey, our product chat information service is designed to empower customers and enrich their interactions with your brand.",
    },
  ],
  [
    {
      heading: "TROUBLESHOOTING CHAT",
      details:
        "Experience seamless issue resolution with our Troubleshooting Chat Support service! Our adept chat support agents are at your service 24/7, providing expert assistance in identifying and resolving issues with products or services. Trained to offer step-by-step guidance, they excel in helping customers navigate your website or mobile app, sharing troubleshooting tips, and swiftly addressing queries. Count on us for efficient issue resolution, ensuring your customers receive expert help whenever they need it.",
    },
  ],
  [
    {
      heading: "EMAIL CHAT SUPPORT SERVICES",
      details:
        "Experience the epitome of convenience with our Email Chat Support Services. Designed for seamless communication, this service allows customers to connect with our highly trained support agents via email for prompt resolution of inquiries, complaints, and feedback. Our team is well-equipped to handle a spectrum of concerns, from technical support to sales inquiries and product information. Leveraging advanced tools and technologies, we ensure swift processing of emails, delivering accurate and effective solutions. Our commitment is to provide outstanding customer service, fostering enduring relationships with our valued clients.",
    },
  ],
];
