import React from "react";
import { Col, Container, Row } from "reactstrap";
import call1 from "../assets/services/sales.jpg";
import Differentiator from "../components/differentiator";
import CallToAction from "../components/callToAction";

const Sales = () => {
  return (
    <Container fluid="true">
      <div className="portfolio-banner-wrapper">
        <div className="portfolio-banner">
          <Container>
            <div className="portfolio-banner-content">
              <div>
                <p className="heading">SALES AGENTS & ISA</p>
              </div>
              <div>
                <p className="link">
                  <span className="active">Services </span>/ Sales Agents
                </p>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <br />
      <section>
        <Container>
          <h2>Empower Your Sales Strategy</h2>
          <p>
            Together, our Sales Agents and ISAs form a dynamic force, dedicated
            to propelling your business towards success. Partner with us today
            and experience the difference in your sales performance.
            <br />
            <br />
            Unlock the full potential of your sales efforts with our team of
            dedicated Sales Agents and Inside Sales Associates (ISAs).
          </p>
        </Container>
      </section>
      <br />
      <section className="about-banner-wrapper services-bg">
        <Container fluid="true" className="home-services ">
          <h2>Everything You Need To Know!</h2>
        </Container>
      </section>
      <br />
      <br />
      <section>
        <Container>
          <Row className="services-row px-4">
            <Col lg="6" md="6" sm="12">
              <img
                src={call1}
                alt="calling 1"
                className="services-call-image"
              />
            </Col>
            <Col lg="6" md="6" sm="12">
              <p className="about-heading">SALES AGENTS & ISA</p>
              <p>
                Our Sales Agents are seasoned professionals who bring invaluable
                experience and expertise to the table, ensuring your business
                benefits from top-notch sales representation. With a deep
                understanding of industry trends and customer needs, they excel
                at driving conversions and fostering long-term relationships.
                <br />
                <br />
                Complementing our Sales Agents are our skilled Inside Sales
                Associates (ISAs), adept at qualifying leads, nurturing
                prospects, and maximizing sales opportunities through effective
                communication and targeted outreach.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <br />
      <br />
      <section>
        <Container>
          <div className="sales-grid">
            <div>
              <p className="heading">LEAD GENERATION</p>
              <p>
                Supercharge your business growth with our Lead Generation
                prowess! Leveraging dynamic methods including strategic cold
                calling, targeted email campaigns, and impactful social media
                outreach, we don't just generate leads – we qualify and gauge
                their genuine interest to ensure every opportunity is primed for
                success.
              </p>
            </div>
            <div>
              <p className="heading">SALES FUNNEL MANAGEMENT</p>
              <p>
                Drive your sales success with our Sales Funnel Management
                expertise! Seamlessly navigating every stage of the sales
                process, our dedicated team ensures leads progress effortlessly
                through the funnel. Through insightful data analysis, we
                pinpoint areas for enhancement and collaborate closely with your
                sales team to elevate performance and maximize opportunities.
              </p>
            </div>
            <div>
              <p className="heading">CRM MANAGEMENT AND AUTOMATION</p>
              <p>
                Elevate your customer relationships with precision through our
                CRM Management and Automation services! Our experts not only
                implement and maintain cutting-edge CRM systems to streamline
                your customer data but also automate crucial tasks like lead
                capture and follow-up. Unleash the potential of your sales team
                by letting us handle the details, ensuring they can concentrate
                on sealing the deal.
              </p>
            </div>
            <div>
              <p className="heading">EMAIL MARKETING AND AUTOMATION</p>
              <p>
                Transform your marketing game with our Email Marketing and
                Automation expertise! Crafting compelling campaigns that
                captivate your audience and boost conversions is our forte. Our
                team goes beyond by automating tasks like precise list
                segmentation and timely email scheduling, ensuring you save
                valuable time and resources while maximizing impact.
              </p>
            </div>
            <div>
              <p className="heading">SOCIAL MEDIA MARKETING & MANAGEMENT</p>
              <p>
                Amplify your brand presence with our Social Media Marketing &
                Management prowess! From crafting engaging content on platforms
                like Facebook, Twitter, and LinkedIn to building and nurturing a
                powerful online identity, our dedicated team ensures your brand
                shines brightly in the social media landscape.
              </p>
            </div>
            <div>
              <p className="heading">SALES TRAINING AND COACHING</p>
              <p>
                Empower your sales force with our Sales Training and Coaching
                excellence! Elevate skills, boost success—our expert-led
                training programs are custom-tailored to identify and refine
                areas for improvement within your team, ensuring they are
                equipped with the expertise needed for triumph in the dynamic
                world of sales.
              </p>
            </div>
            <div>
              <p className="heading">CALL CENTER SUPPORT AND OUTSOURCING</p>
              <p>
                Elevate customer support efficiency with our Call Center Support
                and Outsourcing solutions! From expertly handling customer
                inquiries to optimizing support processes, our team is your
                partner in enhancing service delivery. Additionally, by
                outsourcing non-core functions, we assist in streamlining
                operations and reducing costs for greater business efficiency.
              </p>
            </div>
            <div>
              <p className="heading">MARKET RESEARCH AND ANALYSIS</p>
              <p>
                Navigate the market with confidence through our Market Research
                and Analysis services! Uncover deep insights into your target
                audience, outpace the competition, and make informed business
                decisions. Our dedicated team delves into consumer behavior and
                trends, ensuring you stay at the forefront of your industry.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <br />
      <Differentiator />
      <br />
      <br />
      <CallToAction />
    </Container>
  );
};

export default Sales;
